* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    background-color: #f4f4f4;
    color: #333;
  }
  
  .app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

  }
  
  .content {
    flex: 1;
  }
  
  .footer {
    margin-top: auto;
  }
  